import { template as template_920ffacccf364a8891ea39b61f7194e8 } from "@ember/template-compiler";
const SidebarSectionMessage = template_920ffacccf364a8891ea39b61f7194e8(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
