import { template as template_3c0e6c5ef59441e58b15a9834db5f54a } from "@ember/template-compiler";
const FKText = template_3c0e6c5ef59441e58b15a9834db5f54a(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
