import { template as template_980c98f69ba84820b99c8d7b97f9d513 } from "@ember/template-compiler";
import Component from "@glimmer/component";
import DButton from "discourse/components/d-button";
export default class PostMenuShowMoreButton extends Component {
    static shouldRender(args) {
        return args.state.collapsedButtons.length && args.state.collapsed;
    }
    static{
        template_980c98f69ba84820b99c8d7b97f9d513(`
    <DButton
      class="post-action-menu__show-more show-more-actions"
      ...attributes
      @action={{@buttonActions.showMoreActions}}
      @icon="ellipsis-h"
      @title="show_more"
    />
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
